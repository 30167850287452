import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import CustomModal from "components/CustomModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "./components/Header";
import { loadUser } from "api/auth";
import { connect } from "react-redux";
import { Space, Switch } from 'antd';
import './components/switch.css'; 
import { Divider } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'white',
        },
      },
    },
  },
});

const style = {
  py: 0,
  width: '100%',
  maxWidth: 360,
  borderRadius: 2,
  border: '2px solid',
  borderColor: '#ffffff',
  backgroundColor: 'background.paper',
};

function Billing({ loadUser, user }) {

  const [checked, setChecked] = React.useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);
    if (user === null) {
      loadUser();
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [loadUser, user]);
  const [storedValue, setStoredValue] = React.useState(false);
  const resetState = () => setStoredValue(false);
  const [redirectUrl, setRedirectUrl] = React.useState(null);

  const handleSubscriptionClick = (url) => {
    setRedirectUrl(url);
    setStoredValue(true); 
  };
  
  const handleRedirect = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };
  
  React.useEffect(() => {
    if (!storedValue && redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [storedValue, redirectUrl]);
  

  return (
    
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
      <CustomModal 
  open={storedValue} 
  setOpen={setStoredValue} 
  resetState={resetState} 
  title="Error" 
  onClose={handleRedirect}
  content="Please use the same email for Stripe that you used with to sign up with on Copier Cat. If you are not able to use that email please contact support@copiercat.com" 
/>
        <MDBox mt={5} mb={3}>
          <div container spacing={1} className='p-4 justify-center'>
            <div className="flex justify-end max-w-[160px] m-auto mb-1">
              <div className="bg-[#32EABD] rounded-xl w-[85px]">
                <p className="text-black text-[12px] font-[800] text-center">-15%</p>
              </div>
            </div>
            <div className="flex justify-center mb-5">
              <span className="switcher switcher-1">
                <input 
                  type="checkbox" 
                  id="switcher-1" 
                  checked={checked} 
                  onChange={handleToggle} 
                />
                <label htmlFor="switcher-1"></label>
              </span>
            </div>
            <div className="md:flex justify-center md:space-x-5 md:space-y-0 space-y-5">
              <div className="bg-[#1A73E8] min-w-[290.66px] rounded-2xl p-1 h-[765px] relative">
                <p className="text-white font-[600] text-[44px] text-center mt-3">Basic</p>
                <div className="bg-black w-full pt-3 mt-5 rounded-b-2xl h-auto">
                  <div className="flex justify-center">
                    {!checked && <p className="text-white font-[400] text-[19.5px] text-center mt-12 mr-1 line-through">$10.99</p>}
                    <p className="text-white font-[800] text-[19.5px] text-center mt-5">$</p>
                    <p className="text-white font-[800] text-[65px] text-center h-[85px]">{checked? '10.99' : '9.34'}</p>
                  </div>
                  <p className="text-white font-[400] text-[16px] text-center mb-[40px]">Monthly</p>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">1 Trading account link</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">Unlimited trades</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">Master Senders : TradingView, Meta Trader 4, Meta Trader 5, Trade Locker</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto mb-[133px]">Possible Receivers :TradeLocker, Matchtrader,  Meta Trader 4, Meta Trader 5</p> 
                  <div className="flex justify-center mb-3">
                  <a 
  onClick={() => handleSubscriptionClick(checked ? "https://buy.stripe.com/eVa7ssbyw46w2IM144" : "https://buy.stripe.com/aEU1445a85aAgzC7su")}
  className="bg-[#1A73E8] w-[173px] h-[60px] text-white text-center content-center text-[18px] font-[500] rounded-2xl hover:bg-blue-300"
>
  Get Started
</a>
                  </div>
                  <p className="text-white font-[400] text-[13px] text-center max-w-[260px] m-auto pb-[35px]"></p> 
                </div>
              </div>
              
              <div className="bg-[#1A73E8] min-w-[290.66px] rounded-2xl p-1 h-[765px]">
                <p className="text-white font-[600] text-[44px] text-center mt-3">Pro</p>
                <div className="bg-black w-full pt-3 mt-5 rounded-b-2xl">
                  <div className="flex justify-center">
                    {!checked && <p className="text-white font-[400] text-[19.5px] text-center mt-12 mr-1 line-through">$39</p>}
                    <p className="text-white font-[800] text-[19.5px] text-center mt-5">$</p>
                    <p className="text-white font-[800] text-[65px] text-center h-[85px]">{checked? '39' : '33.15'}</p>
                    {!checked && <p className="text-white font-[800] text-[19.5px] text-center mt-5">50</p>}
                  </div>
                  <p className="text-white font-[400] text-[16px] text-center mb-[40px]">Monthly</p>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">5 Trading account links</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">Unlimited trades</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto">Master Senders : TradingView, Meta Trader 4, Meta Trader 5</p> 
                  <div className="px-[36px] my-2">
                    <ThemeProvider theme={theme}>
                      <Divider />
                    </ThemeProvider>
                  </div>
                  <p className="text-white font-[400] text-[14px] text-center max-w-[260px] m-auto mb-[133px]">Possible Receivers :TradeLocker, Matchtrader,  Meta Trader 4, Meta Trader 5</p> 
                  <div className="flex justify-center mb-3">
                  <a 
                  onClick={() => handleSubscriptionClick(checked ? "https://buy.stripe.com/9AQ3cc8mkfPe2IM9AB" : "https://buy.stripe.com/7sI9AAeKIcD2bfi5kn")}
                  className="bg-[#1A73E8] w-[173px] h-[60px] text-white text-center content-center text-[18px] font-[500] rounded-2xl hover:bg-blue-300"
                >
                  Get Started
                </a>
                  </div>
                  <p className="text-white font-[400] text-[13px] text-center max-w-[220px] m-auto pb-[36px]"></p> 
                </div>
              </div>
            </div>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
});

export default connect(mapStateToProps, {loadUser})(Billing);
