
import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Header from "./components/Header";
import { connect } from "react-redux";
import headerBackgroundImage  from "assets/images/info.png";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import { useMaterialUIController } from "context";
import { loadUser } from "api/auth";
import { API_ENDPOINTS } from "apiConfig";
import axiosHelper from '../../Utilities/axiosHelper';
import MDSnackbar from "components/MDSnackbar";
import ThumbnailAccordion from  '../../components/YoutubeAccordian';


const iframeClassName = "w-full md:w-[50%] lg:h-full md:h-[300px] h-[200px] ";



const tutorials = [
  {
    title: "Setting up Meta Trader 4 & 5 (Signal Sender and Receiver)",
    videos: [
      { id: "1rt_MAnpsRI", title: "" }
    ],
    render: (videoLoaded, handleThumbnailClick) => (
      videoLoaded === 0 ? (
        <iframe
          className={iframeClassName}
          src="https://www.youtube.com/embed/1rt_MAnpsRI"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src="https://img.youtube.com/vi/1rt_MAnpsRI/0.jpg"
          alt="Setting up Meta Trader thumbnail"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleThumbnailClick(0)}
        />
      )
    ),
  },
  {
    title: "How to set up CopierCat",
    videos: [
      { id: "L0VpO7uGTdo", title: "Intro to CopierCat" },
      { id: "49Q-4TT-F0w", title: "Setting Up Public Accounts" },
      { id: "kstWsHSjumE", title: "Subscribing to Public Accounts" },
      { id: "FW-nzTaTdJo", title: "New Features in CopierCat" },
      { id: "AXDUk2cCMWA", title: "Custom Trade Settings" },
    ],
    render: (videoLoaded, handleThumbnailClick) => (
      <>
        {[
          { id: "L0VpO7uGTdo", title: "Intro to CopierCat" },
          { id: "49Q-4TT-F0w", title: "Setting Up Public Accounts" },
          { id: "kstWsHSjumE", title: "Subscribing to Public Accounts" },
          { id: "FW-nzTaTdJo", title: "New Features in CopierCat" },
          { id: "AXDUk2cCMWA", title: "Custom Trade Settings" },
        ].map((video, index) => (
          <div key={video.id} style={{ marginBottom: '20px' }}>
            <Typography variant="h6" component="p" style={{ marginBottom: '10px' }}>
              {video.title}
            </Typography>
            {videoLoaded === index ? (
              <iframe
                className={iframeClassName}
                src={`https://www.youtube.com/embed/${video.id}`}
                loading="lazy"
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                src={`https://img.youtube.com/vi/${video.id}/0.jpg`}
                alt={`Thumbnail for ${video.title}`}
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => handleThumbnailClick(index)}
              />
            )}
          </div>
        ))}
      </>
    ),
  },
  {
    title: "How to setup TradeLocker",
    videos: [
      { id: "u204bWqwtpo", title: "" }
    ],
    render: (videoLoaded, handleThumbnailClick) => (
      videoLoaded === 0 ? (
        <iframe
          className={iframeClassName}
          src="https://www.youtube.com/embed/u204bWqwtpo"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src="https://img.youtube.com/vi/u204bWqwtpo/0.jpg"
          alt="How to setup TradeLocker thumbnail"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleThumbnailClick(0)}
        />
      )
    ),
  },
  {
    title: "How to setup Match Trader",
    videos: [
      { id: "etYBBOxO0lE", title: "" }
    ],
    videoIds: ["etYBBOxO0lE"],
    render: (videoLoaded, handleThumbnailClick) => (
      videoLoaded === 0 ? (
        <iframe
          className={iframeClassName}
          src="https://www.youtube.com/embed/etYBBOxO0lE"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src="https://img.youtube.com/vi/etYBBOxO0lE/0.jpg"
          alt="How to setup Match Trader thumbnail"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleThumbnailClick(0)}
        />
      )
    ),
  },
  {
    title: "Using TradingView as a signal generator",
    videos: [
      { id: "L25cQQF9I3o", title: "" }
    ],
    render: (videoLoaded, handleThumbnailClick) => (
      videoLoaded === 0 ? (
        <iframe
          className={iframeClassName}
          src="https://www.youtube.com/embed/L25cQQF9I3o"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src="https://img.youtube.com/vi/L25cQQF9I3o/0.jpg"
          alt="Using Tradingview as a trade generator thumbnail"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleThumbnailClick(0)}
        />
      )
    ),
  },
  {
    title: "Using Telegram as a signal sender",
    videos: [
      { id: "ajujBgJCWmA", title: "Click thumbnail to view" }
    ],
    render: (videoLoaded, handleThumbnailClick) => (
      videoLoaded === 0 ? (
        <iframe
          className={iframeClassName}
          src="https://www.youtube.com/embed/ajujBgJCWmA"
          loading="lazy"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      ) : (
      <img
        src="https://img.youtube.com/vi/ajujBgJCWmA/0.jpg"
        alt="Using Telegram as a trade generator thumbnail"
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={() => handleThumbnailClick(0)}
      />
      )
    ),
  }
];


function DownloadTutorial({ user, loadUser }) {

  React.useEffect(() => {
    getVersions();
    if (user === null) {
      loadUser();
    }
  },[loadUser, user]);
  const [successSB, setSuccessSB] =  React.useState(false);
  const [errorSB, setErrorSB] =  React.useState(false);
  const [content, setContent] =  React.useState("");

  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
      <MDSnackbar
        color='success'
        icon='check'
        title='Admin'
        content={content}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );
  
    const renderErrorSB = (
      <MDSnackbar
        color='error'
        icon='warning'
        title='Admin'
        content={content}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    );

  const [version, setVersion] = React.useState({
    "id": "",
    "metaTrader4MasterVersion": 1,
    "metaTrader4CopyCatVersion": 1,
    "metaTrader5MasterVersion": 1,
    "metaTrader5MCopyCatVersion": 0
  });
  
  const getVersions = async() => {
    let res = await axiosHelper.get(API_ENDPOINTS.health.downloadTutorial);
    debugger
    if (res.status === 200) {
      debugger
      setVersion(res.data);
    }else{
      setContent("Error getting Download Versions");
      setErrorSB(true);
    }
  }

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const style = React.useMemo(() => {
    const commonStyle = {
      p: 3,
      width: '100%',
      maxWidth: 560,
      borderRadius: 2,
      border: '2px solid',
  
    };
  
    return {
      ...commonStyle,
      borderColor: darkMode ? 'white' : 'divider',
    };
  }, [darkMode]);
  
  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {renderSuccessSB}
      {renderErrorSB}
      <Header backgroundImage={headerBackgroundImage}>
        <MDBox mt={5} mb={3}>
          <div className="md:grid grid-cols-11 px-5 md:pb-16 lg:pb-5">
            <div className="col-span-5 space-y-[0px]">
              <Typography id="transition-modal-title" variant="h3" component="div" sx={{ textDecoration: 'underline', fontWeight: 100 }}>
                <p className="lg:text-[32px] text-[25px]">Tutorials</p>
              </Typography>
              <div>
              <ThumbnailAccordion tutorials={tutorials} darkMode={darkMode} />
    </div>
              

            </div>

            <div className="col-span-1 mt-[90px]" style={{ textAlign: '-webkit-center' }}>
              <Divider component="li" className=" block md:hidden"/>
              <Divider orientation="vertical" className=" hidden md:block" flexItem />
            </div>
            <div className="col-span-5">
              <Typography component="div" id="transition-modal-title" variant="h3" sx={{ textDecoration: 'underline', fontWeight: 100, marginBottom: 2  }}>
                <p className="lg:text-[32px] text-[25px]">Downloads</p>
              </Typography>
              <List sx={style} aria-label="mailbox folders">
                <ListItem
                  alignItems="flex-start"
                  key={1}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatMasterMT4v" + version?.metaTrader4MasterVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader4 Master v" + version?.metaTrader4MasterVersion}sx={{ color: textColor }} />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  key={2}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatSlaveMT4v" + version?.metaTrader4CopyCatVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader4 CopyCat v" + version?.metaTrader4CopyCatVersion}sx={{ color: textColor }} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={3}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatMasterMT5v" + version?.metaTrader5MasterVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader5 Master v" + version?.metaTrader5MasterVersion} sx={{ color: textColor }} />
                </ListItem>

                <ListItem
                  alignItems="flex-start"
                  key={3}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href={"https://www.copiercat.com/downloads/CopierCatSlaveMT5v" + version?.metaTrader5CopyCatVersion + ".zip"} style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary={"MetaTrader5 CopyCat v" + version?.metaTrader5MasterVersion} sx={{ color: textColor }} />
                </ListItem>
                
                <Divider component="li" />
                <ListItem
                  alignItems="flex-start"
                  key={4}
                  disableGutters
                  secondaryAction={
                    user?.isSubscribed &&
                    <IconButton aria-label="comment">
                      <a href="https://www.copiercat.com/downloads/CopierCatPineScriptExample.txt" style={{ textDecoration: 'underline', fontWeight: 100 }}>
                        <DownloadIcon sx={{ color: textColor }} />
                      </a>
                    </IconButton>
                  }
                >
                  <ListItemText primary="Trading View PineScript Example" sx={{ color: textColor }} />
                </ListItem>
              </List>
            </div>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  serverInfo: state.infoPage?.serverInfo,
  user: state.auth?.user,
});

export default connect(mapStateToProps, { loadUser })(DownloadTutorial);
